import React from 'react';
import { Section, Image, Link } from 'components';
import { a, config, useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import * as styles from './styles.module.scss';

const TwoColImageGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { background, drape, cta_anchor_id: anchorId, cta_link: ctaLink, cta_title: ctaText } = primary;
  const startingImageSize = primary?.starting_image_size;

  const hasCta = (ctaLink?.url || anchorId?.text) && ctaText?.text;

  // DATA MANIPULATION
  const rowPairs = [];
  let arrayOne = [];
  let arrayTwo = [];
  let arrayThree = [];

  for (let i = 0; i < items.length; i += 2) {
    const pair = items.slice(i, i + 2);
    rowPairs.push(pair);
  }

  rowPairs.forEach((item, index) => {
    if (item.length % 2 !== 0) {
      arrayThree = [...arrayThree, item];
    }
    if (index % 2 === 0 && item.length % 2 === 0) {
      arrayOne = [...arrayOne, item];
    }
    if (index % 2 !== 0 && item.length % 2 === 0) {
      arrayTwo = [...arrayTwo, item];
    }
  });

  const rowOne = [].concat([], ...arrayOne);
  const rowTwo = [].concat([], ...arrayTwo);
  const rowThree = [].concat([], ...arrayThree);

  // THEME SETUP
  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  const drapeBg = {
    Left: styles.leftDrapeBg,
    Right: styles.rightDrapeBg,
  };

  // ON VIEW CONFIG
  const [refOne, inView] = useInView({
    threshold: 0,
    delay: 0,
    triggerOnce: true,
  });
  const [refTwo, inViewTwo] = useInView({
    threshold: 0,
    delay: 0,
    triggerOnce: true,
  });

  const [refThree, inViewThree] = useInView({
    threshold: 0,
    delay: 0,
    triggerOnce: true,
  });

  // ANIMATIONS
  const aMain = useSpring({
    config: { ...config.molasses },
    delay: 250,
    opacity: inView || inViewTwo ? 1 : 0,
    transform: inView ? 'scale(1)' : 'scale(1.05)',
  });
  const aSecondary = useSpring({
    config: { ...config.molasses },
    delay: 250,
    opacity: inViewTwo ? 1 : 0,
    transform: inView ? 'scale(1)' : 'scale(1.05)',
  });
  const aThird = useSpring({
    config: { ...config.molasses },
    delay: 250,
    opacity: inViewThree ? 1 : 0,
    transform: inView ? 'scale(1)' : 'scale(1.05)',
  });

  const aDrapeLeft = useSpring({
    config: { ...config.molasses },
    delay: 700,
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateX(0px) scale(1)' : 'translateX(-200px) scale(0.8)',
  });

  const aDrapeRight = useSpring({
    config: { ...config.molasses },
    delay: 700,
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateX(0px) rotate(180deg) scale(1)' : 'translateX(200px) rotate(180deg) scale(0.8)',
  });

  return (
    <div className={`${styles.container} ${themes[background]}`}>
      <a.div
        className={drapeBg[drape]}
        alt="Drape"
        style={{
          transform: drape === 'Right' ? aDrapeRight.transform : aDrapeLeft.transform,
          opacity: aDrapeLeft.opacity,
        }}
      />
      <Section sliceName="TwoColImageGrid" className={styles.containerSection}>
        <div className={startingImageSize === 'Smaller' ? styles.rowTwo : styles.rowOne}>
          {rowOne.map((item, index) => {
            const { image, image_link: imageLink } = item;
            return (
              <a.div style={{ opacity: aMain.opacity }} ref={refOne} key={`${index + 1}`}>
                <Image image={image} link={imageLink} />
              </a.div>
            );
          })}
        </div>
        <div className={startingImageSize === 'Smaller' ? styles.rowOne : styles.rowTwo}>
          {rowTwo.map((item, index) => {
            const { image, image_link: imageLink } = item;
            return (
              <a.div style={{ opacity: aSecondary.opacity }} ref={refTwo} key={`${index + 2}`}>
                <Image image={image} link={imageLink} />
              </a.div>
            );
          })}
        </div>

        <div className={styles.rowThree}>
          {rowThree.map((item, index) => {
            const { image, image_link: imageLink } = item;
            return (
              <a.div ref={refThree} style={{ opacity: aThird.opacity }} key={`${index + 3}`}>
                <Image image={image} link={imageLink} />
              </a.div>
            );
          })}
        </div>
        {hasCta && (
          <Link
            className={`button outline ${styles.ctaButton} ${background === 'Dark' ? 'white' : ''}`}
            to={ctaLink.url}
            anchor={anchorId?.text}
          >
            {ctaText.text}
          </Link>
        )}
      </Section>
    </div>
  );
};

export default TwoColImageGrid;
