// extracted by mini-css-extract-plugin
export var title = "styles-module--title--2__nR";
export var container = "styles-module--container--3Z9t8";
export var grid = "styles-module--grid--br6hV";
export var block = "styles-module--block--3CXU-";
export var containerAligned = "styles-module--containerAligned--3dw-u";
export var ctaRow = "styles-module--ctaRow--3TjAo";
export var smallDrapeBg = "styles-module--smallDrapeBg--1wC4y";
export var largeDrapeBg = "styles-module--largeDrapeBg--1vopF";
export var whiteBg = "styles-module--whiteBg--3FIuO";
export var content = "styles-module--content--igrDt";
export var line = "styles-module--line--LBMvV";
export var ctaText = "styles-module--ctaText--AYIMp";
export var offWhiteBg = "styles-module--offWhiteBg--PVlq8";
export var beigeBg = "styles-module--beigeBg--11uOj";
export var lightBg = "styles-module--lightBg--2Mb0Q";
export var text = "styles-module--text--2Ze67";
export var darkBg = "styles-module--darkBg--1lHWH";
export var map = "styles-module--map--3M9DT";
export var classMap = "styles-module--classMap--1TG86";
export var scrollToAnchor = "styles-module--scrollToAnchor--XIlZP";