import React from 'react';
import { Section, Image, Link } from 'components';
import { a, config, useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';

import * as styles from './styles.module.scss';

const TwoColsText = (props) => {
  const { data, isTwoDrapes } = props;
  const { primary, items } = data;
  const {
    background,
    drape,
    title,
    cta_text: ctaText,
    cta_link: ctaLink,
    align_title: alignTitle,
    cta_anchor_id: anchorId,
  } = primary;

  const titleHtml = title?.html;
  const ctaUrl = ctaLink?.url;
  const ctaButtonText = ctaText?.text;
  const hasCta = (ctaUrl || anchorId?.text) && ctaButtonText;

  const slideId = data?.id;
  const hideDrape = slideId === isTwoDrapes || background === 'White';

  // ON VIEW CONFIG
  const [ref, inView] = useInView({
    threshold: 0,
    delay: 200,
    triggerOnce: true,
  });

  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  const drapeBg = {
    Small: styles.smallDrapeBg,
    Large: styles.largeDrapeBg,
  };

  // ANIMATIONS
  const aMain = useSpring({
    config: { ...config.molasses },
    delay: 200,
    opacity: inView ? 1 : 0,
  });

  const aDrape = useSpring({
    config: { ...config.molasses },
    delay: 200,
    transform: inView ? 'translateX(0px) scale(1)' : 'translateX(-200px) scale(0.8)',
  });

  const scrollToId = title?.text.slice(0, -1);
  const isScrollTo = scrollToId === 'Townhouses' || 'Apartments';

  return (
    <div ref={ref} className={themes[background]}>
      <div className={styles.scrollToAnchor} id={isScrollTo && scrollToId} />

      <a.div style={{ opacity: !hideDrape && aMain.opacity }}>
        <a.div className={drapeBg[drape]} style={{ transform: aDrape.transform, zIndex: -1 }} alt="Drape" />
        <Section
          containerClassName={`${alignTitle ? styles.containerAligned : styles.container}`}
          className={styles.wrapper}
          sliceName="TwoColsText"
        >
          {titleHtml && (
            <div
              dangerouslySetInnerHTML={{ __html: titleHtml }}
              className={alignTitle ? styles.titleAligned : styles.title}
            />
          )}
          <div className={styles.grid}>
            {items.map((item, index) => {
              const { column_text: columnText } = item;
              const isMapLocation = columnText?.text === '21 McCabe Street North Fremantle WA 6008';

              return (
                <React.Fragment key={`${index + 1} `}>
                  {' '}
                  {columnText?.html && !isMapLocation && (
                    <div dangerouslySetInnerHTML={{ __html: columnText.html }} className={styles.block} />
                  )}
                  {columnText?.html && isMapLocation && (
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/place/21+McCabe+St,+North+Fremantle+WA+6159/@-32.0217105,115.7554696,17z/data=!3m1!4b1!4m5!3m4!1s0x2a32a3ffdf257a55:0xe4a506fe634d994b!8m2!3d-32.0217105!4d115.7576583"
                      rel="noreferrer"
                      className={styles.map}
                    >
                      <div dangerouslySetInnerHTML={{ __html: columnText.html }} className={styles.classMap} />
                    </a>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {hasCta && (
            <div className={styles.ctaRow}>
              <Link
                className={`button outline ${background === 'Dark' ? 'white' : ''}`}
                to={ctaUrl}
                anchor={anchorId?.text}
              >
                {ctaButtonText}
              </Link>
            </div>
          )}
        </Section>
      </a.div>
    </div>
  );
};

export default TwoColsText;
