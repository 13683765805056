import React from 'react';
import { Link } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ReactComponent as LinkIcon } from 'images/icons/link.svg';
import './styles.scss';

const Image = (props) => {
  const { image, alt, className = '', imgStyle, loading, link } = props;
  if (!image) return <div className={`gatsby-image placeholder ${className}`} />;
  const hasLink = link?.url;

  const imageData = getImage(image?.localFile);

  if (imageData) {
    if (hasLink) {
      return (
        <Link to={hasLink} className="gatsby-img-link-wrapper">
          <LinkIcon className="image-link-icon" />
          <GatsbyImage
            className={`gatsby-image ${className}`}
            loading={loading || 'eager'}
            image={imageData}
            alt={image.alt || alt || ''}
            imgStyle={imgStyle}
          />
        </Link>
      );
    }

    return (
      <GatsbyImage
        className={`gatsby-image ${className}`}
        loading={loading || 'eager'}
        image={imageData}
        alt={image.alt || alt || ''}
        imgStyle={imgStyle}
      />
    );
  }

  if (image.url) {
    if (hasLink) {
      return (
        <Link to={hasLink} className="gatsby-img-link-wrapper">
          <LinkIcon className="image-link-icon" />
          <div className={`gatsby-image ${className}`}>
            <img src={image.url} alt={image.alt || alt || ''} style={imgStyle} />
          </div>
        </Link>
      );
    }
    return (
      <div className={`gatsby-image ${className}`}>
        <img src={image.url} alt={image.alt || alt || ''} style={imgStyle} />
      </div>
    );
  }

  return <div className={`gatsby-image placeholder ${className}`} />;
};

export default Image;
