import React, { useState, useEffect, useRef } from 'react';
import { Section, Image, Link, Carousel } from 'components';
import * as styles from './styles.module.scss';

const InteractiveMap = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { background } = primary;

  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  // ACTIVE STATE CONTROL
  const [currentSelection, setCurrentSelection] = useState(0);

  // REFS
  const carousel = useRef(null);

  const handleClick = (i) => {
    setCurrentSelection(i);
    carousel.current.slickGoTo(i);
  };

  return (
    <>
      <span className={`${themes[background]}`} />
      <Section containerClassName={`${styles.container}`} sliceName="InteractiveMap">
        <div className={styles.imgContainer}>
          <Carousel
            settings={{
              dots: false,
              arrows: false,
              slidesToShow: 1,
              fade: true,
            }}
            sliderRef={carousel}
          >
            {items.map((item, index) => {
              const { map } = item;
              return <Image className={styles.img} image={map} key={`${index + 2}`} />;
            })}
          </Carousel>

          <div className={styles.navContainer}>
            {items.map((item, index) => {
              const { map_label: mapLabel } = item;

              return (
                <p
                  className={`${index === currentSelection && styles.selected} ${styles.navItem}`}
                  key={`${index + 1}`}
                  onClick={() => handleClick(index)}
                  style={{ color: index === currentSelection && item?.text_colour }}
                >
                  {mapLabel.text}
                </p>
              );
            })}
          </div>
        </div>
      </Section>
    </>
  );
};

export default InteractiveMap;
