import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import SimpleReactLightbox from 'simple-react-lightbox-pro';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { a, config, useSpring } from 'react-spring';
import { Section, Image, Video, PlayButton, GalleryBox } from 'components';
import * as styles from './styles.module.scss';

const FullWidthVideo = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    placeholder_image: image,
    youtube_id: videoId,
    hide_cta: hideCta,
    title,
    raw_video: rawVideo,
    autoplay,
    gradient_filter: gradientFilter,
  } = primary;

  // DESTRUCTURING CONDITIONALS
  const titleHtml = title?.html;

  // IF VIDEO
  if (videoId?.text || rawVideo?.url) {
    return (
      <Section
        className={`${styles.section} ${!autoplay ? styles.noAutoPlay : ''}`}
        containerClassName={styles.container}
        sliceName="FullWidthVideo"
      >
        {/* AUTO PLAY VERSION */}
        {!hideCta && (
          <a.a className={styles.exploreToggle} href="#scrollTo">
            <p>Explore</p>
            <div />
            <div />
          </a.a>
        )}
        {autoplay && (
          <ReactPlayer
            className={styles.player}
            style={{ zIndex: -1 }}
            url={rawVideo?.url ? rawVideo.url : `https://www.youtube.com/watch?v=${videoId?.text}`}
            playing
            playsinline
            muted
            loop
            config={{
              youtube: {
                playerVars: { loop: true, autoplay: false, mute: true },
              },
            }}
            width="100%"
            height="100%"
          />
        )}

        {/* NON-AUTOPLAY/LIGHTBOX VERSION */}
        {!autoplay && (
          <div className={styles.containerGalleryBox}>
            <SimpleReactLightbox>
              <GalleryBox
                imagePlaceholder={image}
                lightBoxVideo={rawVideo.url || `https://www.youtube.com/watch?v=${videoId?.text}`}
              />
            </SimpleReactLightbox>
          </div>
        )}
        <div className={styles.anchor} id="scrollTo" />
      </Section>
    );
  }

  // IF IMAGE
  return (
    <Section className={styles.section} containerClassName={styles.container} sliceName="FullWidthVideo">
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}

      <div className={`${styles.imageContainer} ${gradientFilter && styles.filter}`}>
        <Image image={image} className={styles.image} />
      </div>

      {!hideCta && (
        <a.a className={styles.watchNow} href="#scrollTo">
          <p>Explore</p>
          <div />
          <div />
        </a.a>
      )}
      <div className={styles.anchor} id="scrollTo" />
    </Section>
  );
};

export default FullWidthVideo;
