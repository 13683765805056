export const fields = [
  {
    propertyShellField: 'Full Name - Person 1',

    type: 'text',
    name: 'full_name',
    placeholder: 'Full Name*',
    className: 'inputText',
    defaultValue: '',
    validation: { required: true },
    validationMessage: 'Please enter your full name',
  },

  {
    propertyShellField: 'Email - Person 1',

    type: 'email',
    name: 'email',
    placeholder: 'Email address*',
    className: 'inputText',
    defaultValue: '',
    validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
    validationMessage: 'Please enter a valid email',
  },

  {
    propertyShellField: ' Phone - Person 1',

    type: 'tel',
    name: 'mobile',
    placeholder: 'Phone Number*',
    className: 'inputText',
    defaultValue: '',
    validation: { required: true, minLength: 8 },
    validationMessage: 'Please enter a valid number',
  },

  // TODO: This will be updated with https://addressfinder.com.au/ when scope is updated
  {
    type: 'address',
    name: 'suburb',
    placeholder: 'Current Postcode & Suburb of residence*',
    className: 'inputText',
    validationMessage: 'Please enter your Postcode & Suburb of residence',
    defaultValue: '',
    id: 'address_line_1',
  },

  {
    propertyShellField: 'Buyer Type',

    type: 'checkbox',
    name: 'buyer_type',
    placeholder: 'Buyer profile',
    value: 'Buyer profile',
    options: ['First Homebuyer', 'Upgrader', 'Downsizer', 'Local Investor', 'International Investor'],
  },

  {
    propertyShellField: 'Interested In (Type)',

    type: 'checkbox',
    name: 'interested_in_type',
    placeholder: 'I am looking for a',
    value: 'Property Type',
    options: ['Apartment', 'Townhouse', 'Sub Penthouse', 'Penthouse'],
  },
  {
    propertyShellField: ' Interested In (Bedrooms)',

    type: 'checkbox',
    name: 'interested_in_bedrooms',
    placeholder: 'Bedrooms',
    value: 'Property Type',
    options: ['One Bed', 'Two Bed', 'Three Bed', 'Four Bed'],
  },

  {
    propertyShellField: 'How Did You Hear About Us?',

    type: 'checkbox',
    name: 'how_did_you_hear_about_us',
    placeholder: 'How did you hear about us?',
    value: 'How did you hear about us?',
    options: [
      'TV',
      'Newspaper',
      'Magazine',
      'Radio',
      'Realestate.com.au',
      'Domain.com.au',
      'Urban',
      'Referral',
      'Friends/Family',
      'Direct Mail',
      'Digital Display Ad',
      'Google',
      'Social Media',
      'Signage',
      'Other',
    ],
  },

  {
    propertyShellField: ' Phone - Person 1',

    type: 'textarea',
    name: 'activity_message',
    placeholder: 'Message',
    className: 'textarea',
    defaultValue: '',
  },
];
