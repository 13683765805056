/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Section, Image, Carousel } from 'components';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox-pro';
import { colorSelector } from '../../helpers/colorSelector';
import * as styles from './styles.module.scss';

const ImageCarousel = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    is_a_lightbox: lightbox,
    title,
    main_image: mainImage,
    background,
    align_image_right: alignRight,
    center_align: alignCenter,
  } = primary;

  const [width, setWidth] = useState(null);

  // CONDITIONALS
  const titleHtml = title?.html;
  const hasImage = mainImage?.url;

  // BACKGROUND SETUP
  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  // LIGHTBOX SETUP
  const lightboxOptions = {
    settings: {
      autoplaySpeed: 3000,
      boxShadow: 'none',
      disableKeyboardControls: false,
      disablePanzoom: true,
      disableWheelControls: false,
      hideControlsAfter: 3000,
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: 'linear',
      overlayColor: 'rgba(47, 52, 71, 0.9)',
      slideAnimationType: 'fade',
      slideSpringValues: [300, 50],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: 'linear',
      usingPreact: false,
    },
    buttons: {
      backgroundColor: 'rgba(47, 52, 71, 0.8)',
      iconColor: 'rgba(255, 255, 255, 0.8)',
      iconPadding: '10px',
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: true,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
      size: '40px',
    },
    thumbnails: {
      showThumbnails: false,
      thumbnailsAlignment: 'center',
      thumbnailsContainerBackgroundColor: 'transparent',
      thumbnailsContainerPadding: '0',
      thumbnailsGap: '0 5px',
      thumbnailsIconColor: '#ffffff',
      thumbnailsOpacity: 0.4,
      thumbnailsPosition: 'bottom',
      thumbnailsSize: ['100px', '80px'],
    },
  };

  useEffect(() => {
    if (!width) {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  const isMobile = width <= 786;

  const GalleryBox = ({ icon, images }) => {
    const { openLightbox, closeLightbox } = useLightbox();
    return (
      <>
        <div className={`${styles.grid}`} onClick={() => openLightbox()}>
          {hasImage && <Image image={mainImage} className={styles.image} />}
          <div className={`${styles.content}`}>
            {titleHtml && (
              <div
                dangerouslySetInnerHTML={{ __html: titleHtml }}
                className={styles.title}
                onClick={() => openLightbox()}
              />
            )}
            <div className={styles.buttonContainer}>
              <a className={`button outline ${background === 'Dark' ? 'white' : ''}`} onClick={() => openLightbox()}>
                View Gallery
              </a>
            </div>
          </div>
        </div>
        <SRLWrapper options={lightboxOptions}>
          {items?.map((item, index) => {
            const { image, video, image_link: imageLink } = item;
            const isImage = image?.url;
            const hasLink = imageLink?.url;

            return (
              <div className={styles.imageContainerLightBox} key={`${index + 1}`}>
                {isImage || hasLink ? (
                  <>
                    {hasLink ? (
                      <a href={hasLink}>
                        <img src={isImage} alt="" />
                      </a>
                    ) : (
                      <img src={isImage} alt="" />
                    )}
                  </>
                ) : (
                  <div>
                    <a
                      srl_video_thumbnail={video?.thumbnail_url}
                      srl_video_caption={video?.title}
                      srl_video_loop="true"
                      srl_video_scale="80"
                      href={video?.embed_url}
                    >
                      <img src={video?.thumbnail_url} alt="serai" />
                    </a>
                  </div>
                )}
              </div>
            );
          })}
        </SRLWrapper>
      </>
    );
  };

  const Lightbox = () => (
    <SimpleReactLightbox>
      <GalleryBox />
    </SimpleReactLightbox>
  );

  if (!lightbox) {
    return (
      <Section containerClassName={styles.container} sliceName="ImageCarousel">
        <Carousel
          settings={{
            dots: false,
            arrows: false,
            slidesToShow: isMobile ? 1.2 : 2.8,
            draggable: true,
          }}
        >
          {items?.map((item, index) => {
            const { image, image_link: imageLink } = item;
            const isImage = image?.url;

            return (
              <React.Fragment key={`${index + 2}`}>
                {isImage && (
                  <div className={styles.imageContainer}>
                    <div className={styles.itemColumn}>
                      <Image
                        image={image}
                        link={imageLink}
                        className={index % 2 !== 0 ? styles.imageLarger : styles.imageSmaller}
                      />
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </Carousel>
      </Section>
    );
  }

  const classNames = `${styles.containerLightbox} ${alignRight ? styles.rightToLeft : ''} ${
    alignCenter ? styles.alignCenter : ''
  }`;
  return (
    <div className={themes[background]}>
      <Section containerClassName={classNames} sliceName="ImageCarousel">
        <Lightbox />
      </Section>
    </div>
  );
};

export default ImageCarousel;
