// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--2BnZi";
export var container = "styles-module--container--5-Q7m";
export var quote = "styles-module--quote--3KaHD";
export var name = "styles-module--name--2gqux";
export var job = "styles-module--job--3ZiVe";
export var divider = "styles-module--divider--apPwf";
export var leftDrapeBg = "styles-module--leftDrapeBg--27IhJ";
export var rightDrapeBg = "styles-module--rightDrapeBg--3AeCu";
export var whiteBg = "styles-module--whiteBg--1Wtic";
export var grid = "styles-module--grid--3Jops";
export var content = "styles-module--content--3Yz1w";
export var line = "styles-module--line--kG3sS";
export var offWhiteBg = "styles-module--offWhiteBg--2ayQG";
export var beigeBg = "styles-module--beigeBg---0HNh";
export var lightBg = "styles-module--lightBg--BhSqc";
export var text = "styles-module--text--3D8mH";
export var darkBg = "styles-module--darkBg--Z1dfg";