import React, { useState } from 'react';
import { Section } from 'components';
import { ReactComponent as Dowload } from '../../images/icons/download.svg';

import * as styles from './styles.module.scss';

const DownloadAccordion = (props) => {
  const [activeIndex, setActiveIndex] = useState([]);

  const { data } = props;
  const { primary, items } = data;
  const {
    title,
    is_accordion_style: isAccordion,
    download_all_cta_text: downloadAllCTA,
    download_all_file: downloadAllFile,
  } = primary;
  const titleHtml = title?.html;

  const downloadAll = (itemUrl) => {
    fetch(itemUrl, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', downloadAllFile?.raw?.name);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ==formatted data for accordion== \\
  const itemsSorted = {};

  items.forEach((item) => {
    const titleText = item?.title?.text?.trim();
    itemsSorted[titleText] = itemsSorted[titleText] || [];
    itemsSorted[titleText].push(item);
  });
  const finalArray = Object.keys(itemsSorted).map((key) => [String(key), itemsSorted[key]]);
  // ===============================\\

  const handleOnClick = (event, index) => {
    event.preventDefault();
    setActiveIndex((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((prevIndex) => prevIndex !== index);
      }
      return [...prevIndexes, index];
    });
  };

  const Item = ({ item }) => {
    const innerItems = item?.[1];

    return (
      <>
        {innerItems.map((downloadItem, index) => {
          const {
            cta_text: ctaText,
            item_one: itemOne,
            item_two: itemTwo,
            item_three: itemThree,
            value_one: valueOne,
            value_two: valueTwo,
            value_three: valueThree,
            download_item: itemDownload,
          } = downloadItem;

          const itemOneHtml = itemOne?.html;
          const valueOneHtml = valueOne?.html;
          const itemTwoHtml = itemTwo?.html;
          const valueTwoHtml = valueTwo?.html;
          const itemThreeHtml = itemThree?.html;
          const valueThreeHtml = valueThree?.html;

          const download = (itemUrl) => {
            fetch(itemUrl, {
              method: 'GET',
              headers: {},
            })
              .then((response) => {
                response.arrayBuffer().then(function (buffer) {
                  const url = window.URL.createObjectURL(new Blob([buffer]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', itemDownload?.raw?.name);
                  document.body.appendChild(link);
                  link.click();
                });
              })
              .catch((err) => {
                console.log(err);
              });
          };

          return (
            <div className={styles.answerRow} key={`${index + 5}`}>
              <div className={styles.items}>
                <div className={styles.item}>
                  <div className={styles.itemOne}>
                    {itemOneHtml && <div dangerouslySetInnerHTML={{ __html: itemOneHtml }} className={styles.answer} />}
                  </div>
                  <div className={styles.valueOne}>
                    {valueOneHtml && (
                      <div dangerouslySetInnerHTML={{ __html: valueOneHtml }} className={styles.answer} />
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.itemOne}>
                    {itemTwoHtml && <div dangerouslySetInnerHTML={{ __html: itemTwoHtml }} className={styles.answer} />}
                  </div>
                  <div className={styles.valueOne}>
                    {valueTwoHtml && (
                      <div dangerouslySetInnerHTML={{ __html: valueTwoHtml }} className={styles.answer} />
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.itemOne}>
                    {itemThreeHtml && (
                      <div dangerouslySetInnerHTML={{ __html: itemThreeHtml }} className={styles.answer} />
                    )}
                  </div>
                  <div className={styles.valueOne}>
                    {valueThreeHtml && (
                      <div dangerouslySetInnerHTML={{ __html: valueThreeHtml }} className={styles.answer} />
                    )}
                  </div>
                </div>
              </div>

              {itemDownload?.url && (
                <div className={styles.buttonContainer}>
                  <a
                    download
                    onClick={() => download(itemDownload?.url)}
                    role="button"
                    tabIndex={0}
                    className={styles.downloadButton}
                  >
                    {downloadItem?.cta_text?.text}
                    <span className={styles.icon}>
                      <Dowload height={20} fill="#2F3447" />
                    </span>
                  </a>
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <Section
      sliceName="DownloadAbleAccordion"
      className={styles.downloadAccordion}
      containerClassName={styles.accordionContainer}
    >
      {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}

      <div className={styles.accordionItems}>
        {finalArray.map((item, index) => {
          const isActive = activeIndex.includes(index);
          const rowTitle = item[0];
          const itemsNoAccordian = item?.[1];
          const ctaTextNoAccordian = itemsNoAccordian?.[0]?.cta_text;
          const imageDownloadNoAccordian = itemsNoAccordian?.[0]?.download_item;

          const download = (itemUrl) => {
            fetch(itemUrl, {
              method: 'GET',
              headers: {},
            })
              .then((response) => {
                response.arrayBuffer().then(function (buffer) {
                  const url = window.URL.createObjectURL(new Blob([buffer]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', imageDownloadNoAccordian?.raw?.name);
                  document.body.appendChild(link);
                  link.click();
                });
              })
              .catch((err) => {
                console.log(err);
              });
          };

          return (
            <div
              className={`${styles.accordionItem} ${isActive ? styles.active : styles.inactive}`}
              key={`${index + 10}`}
            >
              <div
                className={styles.titleRow}
                aria-hidden="true"
                onClick={isAccordion ? (event) => handleOnClick(event, index) : null}
                role="button"
              >
                {rowTitle && (
                  <div className={styles.question}>
                    <p>{rowTitle}</p>
                  </div>
                )}
                <div className={styles.accordionItemToggleContainer}>
                  {isAccordion && <div className={styles.accordionItemToggle} />}
                  {!isAccordion && ctaTextNoAccordian?.text && imageDownloadNoAccordian?.url && (
                    <a onClick={() => download(imageDownloadNoAccordian?.url)} role="button" tabIndex={0}>
                      {ctaTextNoAccordian?.text}
                      <span className={styles.icon}>
                        <Dowload height={20} />
                      </span>
                    </a>
                  )}
                </div>
              </div>

              <div className={styles.answer}>
                <Item item={item} />
              </div>
            </div>
          );
        })}
      </div>
      {downloadAllCTA?.text && downloadAllFile?.url && (
        <div
          className={styles.buttonOutline}
          onClick={() => downloadAll(downloadAllFile?.url)}
          role="button"
          tabIndex={0}
        >
          {downloadAllCTA?.text}
        </div>
      )}
    </Section>
  );
};

export default DownloadAccordion;
