// extracted by mini-css-extract-plugin
export var form = "styles-module--form--s0bac";
export var field = "styles-module--field--1oqYM";
export var splitLeft = "styles-module--split-left--3dAwW";
export var splitRight = "styles-module--split-right--3ZcSp";
export var checkBox = "styles-module--checkBox--1xoQz";
export var label = "styles-module--label--2b3Ko";
export var formButton = "styles-module--formButton--XzAln";
export var buttonRow = "styles-module--buttonRow--2bW94";
export var fieldError = "styles-module--fieldError--itr-G";
export var active = "styles-module--active--HsEcw";
export var privacyPolicy = "styles-module--privacyPolicy--1BVXk";
export var buttonText = "styles-module--buttonText--3MpHM";
export var successMessage = "styles-module--successMessage--2HNnI";