import React, { useState, useEffect } from 'react';
import { Link, Logo, Navigation } from 'components';
import { a, config, useSpring } from 'react-spring';
import { graphql, useStaticQuery } from 'gatsby';
import * as styles from './styles.module.scss';
import { ReactComponent as Hamburger } from '../../images/icons/hamburger.svg';

const Header = ({ uid }) => {
  const navQuery = graphql`
    query {
      ...HeaderNavLinks
    }
  `;

  const {
    prismicSettings: { data: navLinks },
  } = useStaticQuery(navQuery);

  const { navigation_links: navItems } = navLinks;

  // STATE CONTROL
  const [isShrunk, setShrunk] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);

  // DETERMINE SCROLL POSITION
  useEffect(() => {
    const onScroll = () => {
      setShrunk((isShrunk) => {
        if (!isShrunk && (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)) {
          return true;
        }

        if (isShrunk && document.body.scrollTop < 4 && document.documentElement.scrollTop < 4) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // ANIMATIONS
  const aLogo = useSpring({
    config: { ...config.molasses },
    delay: 200,
    from: { opacity: 0, transform: 'translateY(-20px) scale(1.1)' },
    to: { opacity: 1, transform: 'translateY(0px) scale(1)' },
  });

  const aContents = useSpring({
    config: { ...config.molasses },
    delay: 200,
    from: { opacity: 0, transform: 'translateX(50px) scale(1.1)' },
    to: { opacity: 1, transform: 'translateX(0px) scale(1)' },
  });

  return (
    <header className={`${styles.header} ${isShrunk ? styles.shrunkHeight : styles.fullHeight}`}>
      <div className={`container ${styles.container}`}>
        <a.span style={{ opacity: aLogo.opacity, transform: aLogo.transform }}>
          <Logo />
        </a.span>
        <a.nav className={styles.navCenter} style={{ opacity: aContents.opacity }}>
          {navItems.map((link, index) => {
            const isActive = link?.navigation_link?.slug === uid;

            const item = link?.navigation_link?.slug?.charAt(0).toUpperCase() + link?.navigation_link?.slug.slice(1);

            return (
              <React.Fragment key={`${index + 1}`}>
                {link?.header && (
                  <Link
                    className={isActive ? styles.buttonActive || '' : styles.button || ''}
                    to={`/${link?.navigation_link?.slug}`}
                  >
                    {item}
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </a.nav>
        <a.div className={styles.navRight} style={{ opacity: aContents.opacity }}>
          <Link className={styles.buttonOutline} to="/contact">
            Enquire<span> Now</span>
          </Link>
          <a.span style={{ opacity: aContents.opacity, transform: aContents.transform }}>
            <Hamburger onClick={() => setMenuToggle(true)} />
          </a.span>
        </a.div>
        <Navigation menuToggle={menuToggle} setMenuToggle={setMenuToggle} uid={uid} />
      </div>
    </header>
  );
};

export default Header;
