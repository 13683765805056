import React from 'react';
import { Link } from 'components';
import { StaticImage } from 'gatsby-plugin-image';
import { siteName } from '../../../config/website';
import * as styles from './styles.module.scss';
import { ReactComponent as LogoLight } from '../../images/logos/serai-logo-light.svg';
import { ReactComponent as LogoDark } from '../../images/logos/serai-logo-dark.svg';

const Logo = ({ dark, className = '' }) => {
  if (dark) {
    return (
      <Link className={`logo-dark ${styles.logoDark} ${className}`} to="/">
        {/* {siteName} */}
        <LogoDark />
      </Link>
    );
  }

  return (
    <Link className={`logo-light ${styles.logo} ${className}`} to="/">
      {/* {siteName} */}
      <LogoLight />
    </Link>
  );
};

export default Logo;
