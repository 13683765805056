// extracted by mini-css-extract-plugin
export var youtubeVideo = "styles-module--youtubeVideo--3U9rA";
export var hiddenEmbed = "styles-module--hiddenEmbed--13In2";
export var playButtonContainer = "styles-module--playButtonContainer--3I8J0";
export var playButton = "styles-module--playButton--2r2hi";
export var placeholderImg = "styles-module--placeholderImg--3sS-4";
export var hidePlaceholder = "styles-module--hidePlaceholder--oF8eJ";
export var whiteBg = "styles-module--whiteBg--DF3-E";
export var offWhiteBg = "styles-module--offWhiteBg--38tLB";
export var beigeBg = "styles-module--beigeBg--iVT66";
export var lightBg = "styles-module--lightBg--1UJ--";
export var darkBg = "styles-module--darkBg--2iMDZ";
export var smallDrapeBg = "styles-module--smallDrapeBg--34f4b";
export var largeDrapeBg = "styles-module--largeDrapeBg--30ZCF";
export var cta = "styles-module--cta--mDQdF";