import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { FormField } from 'components';
import * as styles from './styles.module.scss';

const EnquiryForm = ({
  content,
  location,
  prefilledEmail,
  residenceConfigurationOptions,
  howDidYouHearAboutUsOptions,
  buyerProfileOptions,
  enquirerLocationPlaceholder,
  emailPlaceholder,
  namePlaceholder,
  phoneNumberPlaceholder,
}) => {
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [residenceConfigurationFieldsFormatted, setResidenceConfigurationFieldsFormatted] = useState([
    'Residence Configuration',
  ]);
  const [buyerProfileFieldsFormatted, setBuyerProfileFieldsFormatted] = useState(['Buyer profile']);
  const [howDidYouHearAboutUsFieldsFormatted, sethowDidYouHearAboutUsFieldsFormatted] = useState([
    'How did you hear about us?',
  ]);

  const { errors, dirtyFields } = formState;

  useEffect(
    () =>
      // Set submitting to false in clean up function
      () => {
        setSubmitting(false);
      },
    []
  );

  useEffect(() => {
    // FORMAT SELECT OPTIONS FROM PRISMIC ////
    const doc = new DOMParser().parseFromString(residenceConfigurationOptions?.html, 'text/html');
    const residenceConfigurationFields = [...doc.body.childNodes].map((child) => child.outerHTML || child.textContent);

    const docOne = new DOMParser().parseFromString(howDidYouHearAboutUsOptions?.html, 'text/html');
    const howDidYouHearAboutUsFields = [...docOne.body.childNodes].map((child) => child.outerHTML || child.textContent);

    const docTwo = new DOMParser().parseFromString(buyerProfileOptions?.html, 'text/html');
    const buyerProfileFields = [...docTwo.body.childNodes].map((child) => child.outerHTML || child.textContent);

    if (residenceConfigurationFieldsFormatted?.length <= 1 && residenceConfigurationOptions) {
      setResidenceConfigurationFieldsFormatted(['Residence Configuration', ...residenceConfigurationFields]);
    }
    if (buyerProfileFieldsFormatted?.length <= 1 && buyerProfileOptions) {
      setBuyerProfileFieldsFormatted(['Buyer Profile', ...buyerProfileFields]);
    }
    if (howDidYouHearAboutUsFieldsFormatted?.length <= 1 && howDidYouHearAboutUsOptions) {
      sethowDidYouHearAboutUsFieldsFormatted(['How did you hear about us?', ...howDidYouHearAboutUsFields]);
    }
  });

  const fields = [
    {
      type: 'text',
      name: 'Name*',
      placeholder: namePlaceholder?.text ? namePlaceholder?.text : 'Name*',
      className: 'inputText',
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your name',
    },

    {
      type: 'tel',
      name: 'Phone Number',
      placeholder: phoneNumberPlaceholder?.text ? phoneNumberPlaceholder?.text : 'Phone Number*',
      className: 'inputText',

      defaultValue: '',
      validation: { required: true, minLength: 8 },
      validationMessage: 'Please enter a valid number',
    },
    {
      type: 'email',
      name: 'Email address',
      placeholder: emailPlaceholder?.text ? emailPlaceholder?.text : 'Email address*',
      className: 'inputText',

      defaultValue: prefilledEmail || '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'address',
      name: 'current suburb',
      placeholder: enquirerLocationPlaceholder?.text
        ? enquirerLocationPlaceholder?.text
        : 'Current suburb of residence*',
      className: 'inputText',
      validationMessage: 'Please enter your suburb of residence',

      defaultValue: '',
    },
    {
      type: 'select',
      name: 'Buyer profile',
      placeholder: 'Buyer profile',
      value: 'Buyer profile',
      options: buyerProfileFieldsFormatted,
    },
    {
      type: 'select',
      name: 'Price Range',
      placeholder: 'Price Range',
      value: 'Price Range',
      options: residenceConfigurationFieldsFormatted,
    },
    {
      type: 'select',
      name: 'How did you hear about us?',
      placeholder: 'How did you hear about us?',
      value: 'How did you hear about us?',
      options: howDidYouHearAboutUsFieldsFormatted,
    },
  ];

  const onSubmit = async (values) => {
    setSubmitting(true);
    try {
      const url = 'https://submit-form.com/7rK1eTuo';
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(values),
      };
      const response = await fetch(url, config);
      // const json = await response.json()
      if (response.ok) {
        // return json
        return navigate('/');
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Oops something went wrong, please try again');
      setSubmitting(false);
    }
  };

  return (
    <section>
      {submissionError && <p>{submissionError}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <input
          ref={register()}
          type="checkbox"
          name="_gotcha"
          style={{ display: 'none' }}
          tabIndex="-1"
          autoComplete="off"
        />

        {fields.map((field) => {
          const hasError = dirtyFields[field.name] && errors[field.name];
          return (
            <div key={field.label} className={`${styles.field} ${field.className || ''}`}>
              <FormField {...field} register={register} />
              <div className={`${styles.fieldError} ${hasError ? 'active' : 'inactive'}`}>
                {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
              </div>
            </div>
          );
        })}
        <div className={styles.buttonRow}>
          <button type="submit" className={styles.formButton} disabled={submitting}>
            {submitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </form>
    </section>
  );
};

export default EnquiryForm;
