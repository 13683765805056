// extracted by mini-css-extract-plugin
export var imageContainer = "styles-module--imageContainer--EzQH9";
export var imageSmaller = "styles-module--imageSmaller--OaFHp";
export var imageLarger = "styles-module--imageLarger--3dalu";
export var alignCenter = "styles-module--alignCenter--3mwrl";
export var grid = "styles-module--grid--3HGL5";
export var title = "styles-module--title--2pr4j";
export var buttonContainer = "styles-module--buttonContainer--3IT80";
export var itemColumn = "styles-module--itemColumn--YT2Jn";
export var containerLightbox = "styles-module--containerLightbox--5PG92";
export var image = "styles-module--image--313uB";
export var content = "styles-module--content--1SIrS";
export var text = "styles-module--text--3Uw0Z";
export var line = "styles-module--line--3l-Og";
export var rightToLeft = "styles-module--rightToLeft--tZS-e";
export var imageContainerLightBox = "styles-module--imageContainerLightBox--2mcLT";
export var whiteBg = "styles-module--whiteBg--16IGd";
export var offWhiteBg = "styles-module--offWhiteBg--3UjJr";
export var beigeBg = "styles-module--beigeBg--24uMd";
export var lightBg = "styles-module--lightBg--32VDL";
export var darkBg = "styles-module--darkBg--1dUot";