// extracted by mini-css-extract-plugin
export var grid = "styles-module--grid--hNIwW";
export var image = "styles-module--image--1Z76J";
export var content = "styles-module--content--3Xu43";
export var imageCol = "styles-module--imageCol--bsc2V";
export var text = "styles-module--text--WbxHM";
export var squareImage = "styles-module--squareImage--2HTof";
export var rightToLeft = "styles-module--rightToLeft--3C_jg";
export var whiteBg = "styles-module--whiteBg--3UkjX";
export var line = "styles-module--line--3u1I-";
export var offWhiteBg = "styles-module--offWhiteBg--3Gps7";
export var beigeBg = "styles-module--beigeBg--2yUoK";
export var lightBg = "styles-module--lightBg--1CLhK";
export var darkBg = "styles-module--darkBg--2T_n3";
export var subtitle = "styles-module--subtitle--2fuRY";