// extracted by mini-css-extract-plugin
export var container = "styles-module--container--1z9cR";
export var grid = "styles-module--grid--1FDCC";
export var imgContainer = "styles-module--imgContainer--2X6aa";
export var navContainer = "styles-module--navContainer--1-ZVG";
export var navItem = "styles-module--navItem--1XJcP";
export var selected = "styles-module--selected--2WQzW";
export var smallDrapeBg = "styles-module--smallDrapeBg--1JX4M";
export var largeDrapeBg = "styles-module--largeDrapeBg--2GY0i";
export var whiteBg = "styles-module--whiteBg--24SeV";
export var offWhiteBg = "styles-module--offWhiteBg--199--";
export var beigeBg = "styles-module--beigeBg--1yd-C";
export var lightBg = "styles-module--lightBg--roYeM";
export var text = "styles-module--text--36pwK";
export var darkBg = "styles-module--darkBg--2igR6";