// extracted by mini-css-extract-plugin
export var listItem = "styles-module--listItem--2L1ii";
export var wrapper = "styles-module--wrapper--3Ay09";
export var wrapperImageRight = "styles-module--wrapperImageRight--3W1mI";
export var list = "styles-module--list--1KZHv";
export var listNoTitle = "styles-module--listNoTitle--mP9y8";
export var listWrapper = "styles-module--listWrapper--34sRI";
export var imageWrapper = "styles-module--imageWrapper--1gjiP";
export var listItems = "styles-module--listItems--20k25";
export var switcherButton = "styles-module--switcherButton--2zpYi";
export var optionText = "styles-module--optionText--2qn79";
export var optionTextActive = "styles-module--optionTextActive--2-Lu1";
export var lineLeft = "styles-module--lineLeft--2LuP5";
export var lineRight = "styles-module--lineRight--1IIBN";
export var categoryTitle = "styles-module--categoryTitle--2OLb0";
export var downloadCta = "styles-module--downloadCta--1YCwc";
export var section = "styles-module--section--E5j3R";
export var columnOne = "styles-module--columnOne--AHk5l";
export var columnTwo = "styles-module--columnTwo--3Lwpn";
export var rowWrapper = "styles-module--rowWrapper--RQTNJ";
export var itemTitle = "styles-module--itemTitle--b9xVo";
export var itemText = "styles-module--itemText--3rTzW";
export var image = "styles-module--image--3CwSi";
export var bottomRowLeft = "styles-module--bottomRowLeft--2HDP6";
export var title = "styles-module--title--2GCxX";
export var bottomRowRight = "styles-module--bottomRowRight--2oGRl";
export var download = "styles-module--download--1F8Jf";
export var whiteBg = "styles-module--whiteBg--kUngI";
export var offWhiteBg = "styles-module--offWhiteBg--3lo22";
export var beigeBg = "styles-module--beigeBg--37EuK";
export var lightBg = "styles-module--lightBg--1UuNm";
export var text = "styles-module--text--1o5hD";
export var darkBg = "styles-module--darkBg--16xP1";