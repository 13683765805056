import React, { useState } from 'react';
import { Section, PropertyShellForm } from 'components';
import * as styles from './styles.module.scss';

const ContactForm = (props) => {
  const { data } = props;
  const { primary } = data;

  const { email, name, number, subtitle, title, privacy_policy: privacyPolicy } = primary;
  const [submissionSuccess, setSubmissionSuccess] = useState(null);

  const titleHtml = title?.html;
  const subtitleHtml = subtitle?.html;
  const nameHtml = name?.html;
  const numberHtml = number?.html;
  const emailHtml = email?.html;
  const privacyPolicyUrl = privacyPolicy?.url;

  return (
    <Section className={styles.container} sliceName="ContactForm">
      <div className={styles.row}>
        <div className={styles.columnOne}>
          {submissionSuccess ? (
            <h1>Thank you</h1>
          ) : (
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />
          )}
          <div className={styles.form}>
            <PropertyShellForm
              submissionSuccess={submissionSuccess}
              setSubmissionSuccess={setSubmissionSuccess}
              privacyPolicy={privacyPolicyUrl}
            />
          </div>
        </div>
        {!submissionSuccess && (
          <div className={styles.columnTwo}>
            <div className={styles.agentInfo}>
              {subtitleHtml && <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitleHtml }} />}
              <div className={styles.contactDetails}>
                {nameHtml && <div className={styles.contact} dangerouslySetInnerHTML={{ __html: nameHtml }} />}
                {numberHtml && (
                  <a href={`tel:${number?.text}`}>
                    <div className={styles.contact} dangerouslySetInnerHTML={{ __html: numberHtml }} />{' '}
                  </a>
                )}
                {emailHtml && (
                  <a href={`mailto:${email?.text}`}>
                    <div className={styles.contact} dangerouslySetInnerHTML={{ __html: emailHtml }} />
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

export default ContactForm;
