// extracted by mini-css-extract-plugin
export var footer = "styles-module--footer--22w0l";
export var address = "styles-module--address--23uv_";
export var navContainer = "styles-module--navContainer--lXEzo";
export var colTitle = "styles-module--colTitle--35hzZ";
export var colcontent = "styles-module--colcontent--rDeDF";
export var buttonOutline = "styles-module--buttonOutline--28LTB";
export var columnQuickLinks = "styles-module--columnQuickLinks--3aJWf";
export var column = "styles-module--column--1Avbq";
export var logoContainer = "styles-module--logoContainer--19Eod";
export var row = "styles-module--row--gKlwi";
export var bottomColumn = "styles-module--bottomColumn--CAZa6";
export var privacyPolicy = "styles-module--privacyPolicy--2nipf";
export var padLeft = "styles-module--padLeft--1dElg";