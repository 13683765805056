// extracted by mini-css-extract-plugin
export var downloadAccordion = "styles-module--downloadAccordion--1yl8-";
export var buttonOutline = "styles-module--buttonOutline--2z8d1";
export var downloadButton = "styles-module--downloadButton--2lDzc";
export var buttonContainer = "styles-module--buttonContainer--IeQnt";
export var answerRow = "styles-module--answerRow--Js_nF";
export var itemOne = "styles-module--itemOne--BxNIG";
export var valueOne = "styles-module--valueOne--1u5WF";
export var titleRow = "styles-module--titleRow--pUgyt";
export var items = "styles-module--items--1oPDO";
export var icon = "styles-module--icon--23utx";
export var accordionContainer = "styles-module--accordionContainer--HhDXm";
export var accordionItem = "styles-module--accordionItem--1Gq2g";
export var title = "styles-module--title--2lw1a";
export var question = "styles-module--question--3Rxmi";
export var answer = "styles-module--answer--1H6W0";
export var accordionItemToggleContainer = "styles-module--accordionItemToggleContainer--22x9j";
export var accordionItemToggle = "styles-module--accordionItemToggle--VPeV-";
export var inactive = "styles-module--inactive--2pOGQ";
export var active = "styles-module--active--2NvId";