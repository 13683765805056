/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox-pro';
import FourOhFourImage from 'images/404.svg';
import './styles.scss';

const Link = (props) => {
  const {
    children,
    activeClassName,
    className,
    to,
    title,
    target = '_blank',
    tabIndex,
    ariaLabel,
    onClick = () => {},
    onMouseOver = () => {},
    onMouseOut = () => {},
    anchor,
  } = props;

  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#');

  const containsSiteUrl = process.env.GATSBY_SITE_URL && to && to.indexOf(process.env.GATSBY_SITE_URL) !== -1;

  const isYoutubeLink = to?.includes('yout');

  const lightboxOptions = {
    settings: {
      autoplaySpeed: 3000,
      boxShadow: 'none',
      disableKeyboardControls: false,
      disablePanzoom: false,
      disableWheelControls: false,
      hideControlsAfter: 3000,
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: 'linear',
      overlayColor: 'rgba(47, 52, 71, 0.9)',
      slideAnimationType: 'fade',
      slideSpringValues: [300, 50],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: 'linear',
      usingPreact: false,
    },
    buttons: {
      backgroundColor: 'rgba(47, 52, 71, 0.8)',
      iconColor: 'rgba(255, 255, 255, 0.8)',
      iconPadding: '10px',
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: true,
      size: '40px',
    },
    thumbnails: {
      showThumbnails: false,
      thumbnailsAlignment: 'center',
      thumbnailsContainerBackgroundColor: 'transparent',
      thumbnailsContainerPadding: '0',
      thumbnailsGap: '0 5px',
      thumbnailsIconColor: '#ffffff',
      thumbnailsOpacity: 0.4,
      thumbnailsPosition: 'bottom',
      thumbnailsSize: ['100px', '80px'],
    },
  };

  const GalleryBox = () => {
    const { openLightbox } = useLightbox();
    return (
      <div className="linkGalleryBox">
        <div className="grid" onClick={() => openLightbox()}>
          <div className="content">
            <div>
              <a
                className={className || ''}
                title={title || null}
                aria-label={ariaLabel || null}
                target={target}
                onClick={() => openLightbox()}
                rel="nofollow noopener noreferrer"
                style={{ cursor: 'pointer' }}
              >
                {children}
              </a>
            </div>
          </div>
        </div>
        <SRLWrapper options={lightboxOptions}>
          <div className="imageContainerLightBox">
            <div>
              <a srl_video_loop="true" srl_video_scale="80" href={to}>
                <img src={FourOhFourImage} alt="serai" />
              </a>
            </div>
          </div>
        </SRLWrapper>
      </div>
    );
  };

  if (isYoutubeLink) {
    return (
      <SimpleReactLightbox>
        <GalleryBox />
      </SimpleReactLightbox>
    );
  }

  if ((isExternal && !containsSiteUrl) || anchor) {
    return (
      <a
        href={anchor ? `${to || ''}#${anchor}` : to}
        className={className || ''}
        title={title || null}
        aria-label={ariaLabel || null}
        target={anchor ? null : target}
        onClick={onClick}
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  const withTrailingSlash = `${to}${to.endsWith('/') ? '' : '/'}`;

  const linkTo = containsSiteUrl ? withTrailingSlash.replace(process.env.GATSBY_SITE_URL, '') : withTrailingSlash;

  return (
    <>
      <GatsbyLink
        to={linkTo}
        aria-label={ariaLabel || null}
        activeClassName={activeClassName}
        className={className || ''}
        title={title || null}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        tabIndex={tabIndex}
      >
        {children}
      </GatsbyLink>
    </>
  );
};

export default Link;
