import React from 'react';
import { Section, Image } from 'components';
import { useInView } from 'react-intersection-observer';
import { a, config, useSpring } from 'react-spring';
import * as styles from './styles.module.scss';

const ContactForm = (props) => {
  const { data } = props;
  const { primary, items } = data;

  const { image_one: imageOne, image_two: imageTwo, image_link_1: linkOne, image_link_2: linkTwo } = primary;

  /// / ANIMATIONS ////
  const [ref, inView] = useInView({
    threshold: 0,
    delay: 300,
    triggerOnce: true,
  });

  const aMain = useSpring({
    config: { ...config.molasses },
    opacity: inView ? 1 : 0,
  });

  return (
    <Section className={styles.container} sliceName="ContactForm">
      <a.div className={styles.imageContainer} ref={ref} style={{ opacity: aMain?.opacity }}>
        <Image image={imageOne} link={linkOne} className={styles.image} />
        <Image image={imageTwo} link={linkTwo} className={styles.imageTwo} />
      </a.div>
      <div className={styles.listContainer}>
        {items.map((item) => {
          const { list_item: listItem } = item;

          return (
            <>
              <div className={styles.listItem}>
                <p>{listItem?.text}</p>
              </div>
            </>
          );
        })}
      </div>
    </Section>
  );
};

export default ContactForm;
