import React, { useEffect } from 'react';
import { Section, Image, Link } from 'components';
import { useInView } from 'react-intersection-observer';
import { a, config, useSpring } from 'react-spring';
import * as styles from './styles.module.scss';

const TwoColsImageText = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    align_image_right: alignRight,
    background,
    square_image: square,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    image,
    title,
    subtitle,
    image_link: imageLink,
    cta_anchor_id: anchorId,
  } = primary;

  // background image logic
  const titleHtml = title?.html;
  const subtitleHtml = subtitle?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url;
  const hasCta = ctaText?.text;

  const titleTextParam = title?.text.slice(0, -1);

  const isTownHouses = titleTextParam === 'Townhouses';
  const isApartments = titleTextParam === 'Apartments';

  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  const [ref, inView] = useInView({
    threshold: 0,
    delay: 300,
    triggerOnce: true,
  });

  // ANIMATIONS
  const aMain = useSpring({
    config: { ...config.molasses },
    opacity: inView ? 1 : 0,
  });

  return (
    <div ref={ref} className={themes[background]}>
      <a.div style={{ opacity: aMain.opacity }}>
        {anchorId?.text && <div className="anchor-point" id={anchorId?.text ? anchorId.text : null} />}
        <Section
          containerClassName={`${styles.container} ${square ? styles.squareImage : ''} ${
            alignRight && styles.rightToLeft
          }`}
          sliceName="TwoColsImageText"
        >
          <a.div className={`${styles.grid}`} style={{ opacity: aMain?.opacity }} ref={ref}>
            <div className={styles.imageCol}>
              {hasImage && <Image image={image} className={styles.image} link={imageLink} />}
            </div>
            <div className={`${styles.content}`}>
              {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
              {subtitleHtml && <div dangerouslySetInnerHTML={{ __html: subtitleHtml }} className={styles.subtitle} />}
              {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.text} />}
              {hasCta && (
                <div>
                  <Link
                    className={`button outline ${background === 'Dark' ? 'white' : ''}`}
                    to={isTownHouses || isApartments ? `${ctaLink.url}?scrollTo=${titleTextParam}` : ctaLink.url}
                    anchor={anchorId?.text}
                  >
                    {ctaText.text}
                  </Link>
                </div>
              )}
            </div>
          </a.div>
        </Section>
      </a.div>
    </div>
  );
};

export default TwoColsImageText;
