import React, { useEffect, useState } from 'react';
import { Section, Image, OnVisible, Link } from 'components';
import columnSort from './columnSort';
import * as styles from './styles.module.scss';

const MultiColImageGrid = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { drapes, columns, background, cta_link: ctaLink, cta_text: ctaText } = primary;

  // THEME SETUP
  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  const drapeBg = {
    Small: styles.smallDrapeBg,
    Large: styles.largeDrapeBg,
  };

  const colClass = {
    2: styles.twoCol,
    3: styles.threeCol,
    4: styles.fourCol,
  };

  const breakpoints = {
    desktop: 9999,
    largeTab: 1025,
    tab: 769,
  };

  const [width, setWidth] = useState(null);
  const [breakPoint, setBreakPoint] = useState(null);
  const [sortedColumns, setColumns] = useState();

  const getBreakpoint = (widthRef) => {
    for (const [key, value] of Object.entries(breakpoints)) {
      if (widthRef <= value) {
        setBreakPoint(key);
      }
    }
  };

  const sortCols = (breakpoint) => {
    if (breakpoint === 'desktop') {
      setColumns(columnSort(items, parseInt(columns, 10)));
    }
    if (breakpoint === 'largeTab') {
      if (columns === '4') {
        setColumns(columnSort(items, 3));
      } else {
        setColumns(columnSort(items, parseInt(columns, 10)));
      }
    }
    if (breakpoint === 'tab') {
      if (columns === '4' || columns === '3') {
        setColumns(columnSort(items, 2));
      } else {
        setColumns(columnSort(items, parseInt(columns, 10)));
      }
    }
  };

  // // set columns on mount
  useEffect(() => {
    const widthRef = width || window.innerWidth;
    setWidth(widthRef);
    getBreakpoint(widthRef);
    sortCols(breakPoint);
  }, []);

  // window width listener
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  useEffect(() => {
    const widthRef = width || window.innerWidth;
    getBreakpoint(widthRef);
    sortCols(breakPoint);
  }, [width]);

  return (
    <Section className={`${styles.section} ${themes[background]} ${colClass[columns]} ${drapeBg[drapes]}`}>
      <div className={styles.grid}>
        {sortedColumns?.map((column, i) => (
          <>
            {column?.length > 0 && (
              <div className={styles.column}>
                {column?.map((item) => (
                  <div className={styles.gridItem}>
                    <div className={styles.itemContainer}>
                      {item?.caption?.text && (
                        <div dangerouslySetInnerHTML={{ __html: item?.caption.html }} className={styles.caption} />
                      )}
                      <Image image={item?.image} link={item?.image_link} className={`${styles.image}`} />
                      {item?.image_content?.text && (
                        <div
                          dangerouslySetInnerHTML={{ __html: item?.image_content?.html }}
                          className={styles.imageContent}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ))}
      </div>
      {ctaLink?.url && ctaText?.text && (
        <Link to={ctaLink.url} className={`button outline ${background === 'Dark' ? 'secondary' : ''} ${styles.cta}`}>
          {ctaText.text}
        </Link>
      )}
    </Section>
  );
};

export default MultiColImageGrid;
