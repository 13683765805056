import React from 'react';
import { Logo, Link, FooterForm } from 'components';
import { ReactComponent as MegaraLogo } from '../../images/logos/megara.svg';

import * as styles from './styles.module.scss';

const Footer = () => {
  const links = [
    { to: '/residences', text: 'Residences', className: `${styles.button}` },
    { to: '/vision', text: 'Vision', className: `${styles.button}` },
    { to: '/location', text: 'Location', className: `${styles.button}` },
  ];
  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.container}`}>
        {/* COLUMNS */}
        <div className={styles.navContainer}>
          {/* COLUMN 1 */}
          <div className={styles.column}>
            <span className={styles.colTitle}>SERAI DEVELOPMENT</span>
            <div className={styles.colcontent}>
              <a
                className={styles.address}
                target="_blank"
                href="https://www.google.com/maps/place/21+McCabe+St,+North+Fremantle+WA+6159/@-32.0217105,115.7554696,17z/data=!3m1!4b1!4m5!3m4!1s0x2a32a3ffdf257a55:0xe4a506fe634d994b!8m2!3d-32.0217105!4d115.7576583"
                rel="noreferrer"
              >
                <span>21 McCabe Street</span>
                <span>North Fremantle</span>
                <span>WA 6159</span>
              </a>
            </div>
          </div>
          {/* COLUMN 2 */}
          <div className={styles.columnQuickLinks}>
            <span className={styles.colTitle}>Quick links</span>
            <div className={styles.colcontent}>
              {links.map((link, index) => {
                const { to, text } = link;
                return (
                  <React.Fragment key={`${index + 1}`}>
                    <Link to={to} className={styles.button}>
                      {text}
                    </Link>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          {/* COLUMN 3 */}
          <div className={styles.column}>
            <span className={styles.colTitle}>Sales Agent</span>
            <div className={styles.colcontent}>
              <span>Stephen Vitale</span>
              <a href="tel:0416095906">0416 095 906</a>
              <a href="mailto:stephen@serai.com.au">stephen@serai.com.au</a>
            </div>
          </div>
          {/* COLUMN 4 */}
          <div className={styles.column}>
            {/* <span className={styles.colTitle}>CONTACT US</span>
            <FooterForm /> */}

            <Link className={styles.buttonOutline} to="/contact">
              Enquire Now
            </Link>
            {/* <Logo /> */}
            <a className={styles.logoContainer} href="https://megaraproperty.com.au/" target="_blank" rel="noreferrer">
              <MegaraLogo height={30} width={125} />
            </a>
          </div>
        </div>
        <div className={styles.bottomColumn}>
          <div className={styles.row}>
            <p style={{ color: 'white' }}>
              <div className={styles.row}>
                <span className={styles.privacyPolicy}>
                  <a
                    href="https://serai.cdn.prismic.io/serai/d0983042-8d63-4ae2-8001-4463b789fb81_serai_privacy_policy.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
                <span className={styles.padLeft}>©2021 Megara. All rights reserved.</span>
              </div>
            </p>
          </div>
          <div className={styles.row}>
            <p style={{ color: 'white' }}>
              Images are indicative of the quality and style of the specification and may not represent wholly the
              actual fittings at the development, furniture is shown for illustrative purposes only.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
