// extracted by mini-css-extract-plugin
export var section = "styles-module--section--tumda";
export var noAutoPlay = "styles-module--noAutoPlay--2x7y_";
export var anchor = "styles-module--anchor--1_Rca";
export var container = "styles-module--container--2GBPE";
export var title = "styles-module--title--cGNxI";
export var exploreToggle = "styles-module--exploreToggle--3l7BE";
export var player = "styles-module--player--1w1kq";
export var containerGalleryBox = "styles-module--containerGalleryBox--10lf5";
export var onClickWrapper = "styles-module--onClickWrapper--3eBHE";
export var imageContainer = "styles-module--imageContainer--19W-n";
export var image = "styles-module--image--LsrQW";
export var filter = "styles-module--filter--1vVTL";