// extracted by mini-css-extract-plugin
export var centerAligned = "styles-module--centerAligned--3Wct-";
export var rightAligned = "styles-module--rightAligned--vrmlr";
export var container = "styles-module--container--gFCHK";
export var title = "styles-module--title--1Qd60";
export var content = "styles-module--content--19JG1";
export var ctaRow = "styles-module--ctaRow--VyHVO";
export var offWhiteBg = "styles-module--offWhiteBg--1ZPco";
export var beigeBg = "styles-module--beigeBg--3yCdZ";
export var lightBg = "styles-module--lightBg--17yDB";
export var darkBg = "styles-module--darkBg--2cZ-g";