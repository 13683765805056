import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormField } from 'components';
import * as styles from './styles.module.scss';
import { fields } from './formConfig';

const PropertyShellForm = ({ submissionSuccess, setSubmissionSuccess, privacyPolicy }) => {
  const { register, handleSubmit, formState, setValue } = useForm({ mode: 'onChange' });

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [renderInput, setRenderInput] = useState(true);
  const [buyerProfileItems, setBuyerProfileItems] = useState([]);
  const [interestedInTypeItems, setInterestedInTypeItems] = useState([]);
  const [interestedInBedroomItems, setInterestedInBedroomItems] = useState([]);
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState([]);
  const [address, setAddress] = useState(null);

  const [suburb, setSuburb] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [state, setState] = useState(null);

  const { errors, dirtyFields } = formState;

  const [widget, setWidget] = useState(false);

  // ---------Address finder---------------------

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
    script.async = true;
    script.onload = () => {
      setWidget(
        new window.AddressFinder.Widget(document.getElementById('address_line_1'), '9CENK87M4XTBW6AFJUPY', 'AU', {
          address_params: {
            gnaf: '1',
          },
          show_addresses: false,
          show_locations: true,
          location_params: {
            location_types: 'locality, state',
          },
          max_results: 5,
          empty_content: 'No suburbs were found.',
          manual_style: true,
        })
      );
    };

    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    if (widget) {
      widget.on('result:select', (fullAddress, metaData) => {
        setSuburb(metaData?.locality_name);
        setPostcode(metaData?.postcode);
        setState(metaData?.state_territory);
      });
    }
  }, [address]);

  // -------------------------------------------

  useEffect(() => {
    if (submissionSuccess) {
      window.scrollTo(0, 0);
    }
  }, [submissionSuccess]);

  useEffect(
    () =>
      // Set submitting to false in clean up function
      () => {
        setSubmitting(false);
      },
    []
  );

  const postToPropertyShell = async (values) => {
    setSubmitting(true);
    if (window?.dataLayer) {
      window.dataLayer.push({ event: 'Form-Submit-Success' });
    }
    const queryStrings = typeof window !== 'undefined' ? window.utms : '';
    const referrer = typeof window !== 'undefined' ? window.referrer : 'Direct';
    const queriesObj = queryStrings ? Object.fromEntries(new URLSearchParams(queryStrings)) : {};

    values.project_name = 'Serai';
    values.campaign_name = 'serai.com.au';
    values.utm_source = queriesObj?.utm_source || '';
    values.utm_medium = queriesObj?.utm_medium || '';
    values.utm_campaign = queriesObj?.utm_campaign || '';
    values.utm_content = queriesObj?.utm_content || '';
    values.referrer = referrer;
    if (suburb) {
      values.suburb = suburb;
    }

    values.custom_fields = {
      postcode: postcode || '',
      state: state || '',
      how_did_you_hear_about_us: howDidYouHearAboutUs.length > 0 ? howDidYouHearAboutUs.join(', ') : '',
      buyer_type: buyerProfileItems.length > 0 ? buyerProfileItems.join(', ') : '',
      interested_in_type: interestedInTypeItems.length > 0 ? interestedInTypeItems?.join(', ') : '',
      interested_in_bedrooms:
        interestedInBedroomItems.length > 0 && renderInput ? interestedInBedroomItems?.join(', ') : '',
    };

    const data = JSON.stringify(values);

    try {
      const url = `https://api.propertyshell.com/v2/lead/upsert?access-token=QbDvePPTHssCSJV9_eyEJ_vmQnoALT1G00BFBF1H7r&Content-Type=application/json`;
      const config = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'x-api-key': process.env.PROPERTY_SHELL_API_KEY,
          'Content-Type': 'application/json',
        },
        body: data,
      };
      const response = await fetch(url, config);
      // const json = await response.json()
      if (response.ok) {
        // return json
        setSubmissionSuccess(true);
      }
      //
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Oops something went wrong, please try again');
      setSubmitting(false);
    }
  };

  return (
    <>
      {!submissionSuccess ? (
        <>
          <section className={styles.contactForm}>
            {submissionError && <p>{submissionError}</p>}

            {fields.map((field, index) => {
              const hasError = dirtyFields[field.name] && errors[field.name];
              const isCheckBox = field?.type === 'checkbox';

              return (
                <div
                  key={`${index + 1}`}
                  className={`${isCheckBox ? styles.checkBox : styles.field} ${field.className || ''}`}
                >
                  <FormField
                    {...field}
                    register={register}
                    setValue={setValue}
                    setRenderInput={setRenderInput}
                    renderInput={renderInput}
                    setBuyerProfileItems={setBuyerProfileItems}
                    buyerProfileItems={buyerProfileItems}
                    interestedInTypeItems={interestedInTypeItems}
                    setInterestedInTypeItems={setInterestedInTypeItems}
                    interestedInBedroomItems={interestedInBedroomItems}
                    setInterestedInBedroomItems={setInterestedInBedroomItems}
                    howDidYouHearAboutUs={howDidYouHearAboutUs}
                    setHowDidYouHearAboutUs={setHowDidYouHearAboutUs}
                    setAddress={setAddress}
                    address={address}
                  />
                  <div className={`${styles.fieldError} ${hasError ? 'active' : 'inactive'}`}>
                    {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
                  </div>
                </div>
              );
            })}
          </section>
          <div className={styles.buttonRow}>
            <button
              type="submit"
              className={styles.formButton}
              disabled={submitting}
              onClick={handleSubmit(postToPropertyShell)}
            >
              {submitting ? 'Submitting' : 'Submit'}
            </button>
          </div>
          <p className={styles.buttonText}>
            By clicking submit, you agree to your personal information being collected, held, used and disclosed by us
            in accordance with our{' '}
            <a className={styles.privacyPolicy} href={privacyPolicy} target="_blank" rel="noreferrer">
              Privacy Policy.
            </a>
          </p>
        </>
      ) : (
        <div className={styles.successMessage}>
          Thank you for your interest in Serai. A representative will be in touch with you shortly. If you have any
          immediate questions, please contact Stephen Vitale on <a href="tel:0416 095 906">0416 095 906</a> or{' '}
          <a href="mailto:stephen@serai.com.au">stephen@serai.com.au</a>
        </div>
      )}
    </>
  );
};

export default PropertyShellForm;
