const columnSort = (arr, columnCount) => {
  // declare columns
  const colOne = [];
  const colTwo = [];
  const colThree = [];
  const colFour = [];
  // for each column
  for (let count = 0; count <= columnCount; count += 1) {
    // where x = number of columns
    // get every x index from array and push to appropriate column
    if (columnCount === 2) {
      for (let i = count; i <= arr.length; i += columnCount) {
        if (count === 0) colOne.push(arr[i]);
        if (count === 1) colTwo.push(arr[i]);
      }
    }
    if (columnCount === 3) {
      for (let i = count; i <= arr.length; i += columnCount) {
        if (count === 0) colOne.push(arr[i]);
        if (count === 1) colTwo.push(arr[i]);
        if (count === 2) colThree.push(arr[i]);
      }
    }
    if (columnCount === 4) {
      for (let i = count; i <= arr.length; i += columnCount) {
        if (count === 0) colOne.push(arr[i]);
        if (count === 1) colTwo.push(arr[i]);
        if (count === 2) colThree.push(arr[i]);
        if (count === 3) colFour.push(arr[i]);
      }
    }
  }
  return [colOne, colTwo, colThree, colFour];
};
export default columnSort;
