/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as WhiteTick } from '../../images/icons/white-tick.svg';
import './styles.scss';

const FormField = ({
  name,
  type,
  label,
  options,
  placeholder,
  defaultValue,
  autoFocus,
  handleChange,
  register,
  setValue,
  id,
  validation,
  setRenderInput,
  renderInput,

  buyerProfileItems,
  setBuyerProfileItems,
  setInterestedInTypeItems,
  interestedInTypeItems,
  interestedInBedroomItems,
  setInterestedInBedroomItems,
  howDidYouHearAboutUs,
  setHowDidYouHearAboutUs,
  address,
  setAddress,
}) => {
  const [active, setActive] = useState(false);
  const [inUse, setInUse] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [widget, setWidget] = useState(null);

  const refCopy = [];
  const refs = useRef(refCopy);

  for (let i = 0; i < options?.length; i++) {
    refCopy.push(React.createRef());
  }

  if (type === 'textarea') {
    return (
      <textarea
        {...register(name, validation)}
        name={name}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        className="textArea"
      />
    );
  }
  if (type === 'select') {
    return (
      <select
        name={name}
        {...register(name, validation)}
        className="selectBox"
        onClick={() => setActive(true)}
        style={{ color: active && '#2F3447' }}
      >
        {options?.map((option, index) => (
          <>
            {index === 0 && option === options[0] ? (
              <option selected disabled hidden value={option} defaultValue={defaultValue} key={option}>
                {index !== 0 && option}
              </option>
            ) : (
              <option value={option} defaultValue={defaultValue} key={option} className="selectOption">
                {index !== 0 && option}
              </option>
            )}
          </>
        ))}
      </select>
    );
  }

  const handleCheckBoxClick = () => {
    setActive(!active);
  };

  if (type === 'checkbox') {
    return (
      <>
        <div
          id="list1"
          className="dropdown-check-list"
          style={{ display: name === 'interested_in_bedrooms' && !renderInput && 'none' }}
        >
          <span
            className={active ? 'anchorDown' : 'anchor'}
            role="button"
            tabIndex={0}
            onClick={() => handleCheckBoxClick()}
            style={{ color: inUse && '#2F3447' }}
          >
            {placeholder}
          </span>
          <ul className={active ? 'items' : 'no-items'}>
            {options?.map((option, index) => {
              const handleItemClick = (value) => {
                refs.current?.forEach((item) => {
                  // eslint-disable-next-line no-unused-expressions
                  item?.current?.value === value && item?.current?.click();
                });
              };

              return (
                <li className="listItem" onClick={() => handleItemClick(option)} key={`${index + 1}`}>
                  <p className="dropDown-option">{option}</p>
                  <div className="radio-checkbox">
                    <input
                      id="ref"
                      type="checkbox"
                      className="multi-select-box"
                      value={option}
                      {...register(name, validation)}
                      onClick={() => option === 'Townhouse' && setRenderInput(!renderInput)}
                      onChange={(e) => {
                        // add to list
                        if (e.target.checked) {
                          setSelectedItems([...selectedItems, option]);
                          name === 'buyer_type' && setBuyerProfileItems([...buyerProfileItems, option]);
                          name === 'interested_in_type' && setInterestedInTypeItems([...interestedInTypeItems, option]);
                          name === 'interested_in_bedrooms' &&
                            setInterestedInBedroomItems([...interestedInBedroomItems, option]);
                          name === 'how_did_you_hear_about_us' &&
                            setHowDidYouHearAboutUs([...howDidYouHearAboutUs, option]);
                        } else {
                          // remove from list
                          setSelectedItems(selectedItems?.filter((item) => item !== option));
                          name === 'buyer_type' &&
                            setBuyerProfileItems(buyerProfileItems.filter((item) => item !== option));
                          name === 'interested_in_type' &&
                            setInterestedInTypeItems(interestedInTypeItems.filter((item) => item !== option));
                          name === 'interested_in_bedrooms' &&
                            setInterestedInBedroomItems(interestedInBedroomItems.filter((item) => item !== option));
                          name === 'how_did_you_hear_about_us' &&
                            setHowDidYouHearAboutUs(howDidYouHearAboutUs.filter((item) => item !== option));
                        }
                      }}
                      // ref={ref}
                      ref={refs.current[index]}
                    />

                    <WhiteTick
                      height={10}
                      width={10}
                      className={selectedItems.includes(option) ? 'checkmarkActive' : 'checkmark'}
                      fill="#252531"
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
  return (
    <input
      {...register(name, validation)}
      type={type}
      name={name}
      onChange={(e) => setAddress(e.target.value)}
      placeholder={placeholder}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      id={id}
    />
  );
};

export default FormField;
