import React from 'react';
import { Section, Image, Link } from 'components';
import { useInView } from 'react-intersection-observer';
import { a, config, useSpring } from 'react-spring';
import { colorSelector } from '../../helpers/colorSelector';
import * as styles from './styles.module.scss';

const LargeImage = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    alignment,
    cta_link: ctaLink,
    cta_text: ctaText,
    image,
    background,
    image_link: imageLink,
    cta_anchor_id: anchorId,
  } = primary;

  const hasImage = image?.url;
  const hasCta = (ctaLink?.url || anchorId?.text) && ctaText?.text;

  const alignmentType = (_alignment) => {
    if (_alignment === 'Left') {
      return styles.containerLeft;
    }
    if (_alignment === 'Right') {
      return styles.containerRight;
    }
    if (_alignment === 'Full Width') {
      return styles.fullWidth;
    }
    return '';
  };

  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  // ON VIEW CONFIG
  const [ref, inView] = useInView({
    threshold: 0,
    delay: 200,
    triggerOnce: true,
  });

  // ANIMATIONS
  const aMain = useSpring({
    config: { ...config.molasses },
    delay: 200,
    opacity: inView ? 1 : 0,
    transform: inView ? 'scale(1)' : 'scale(1.05)',
  });

  return (
    <div ref={ref} className={`${themes[background]}`}>
      <Section
        noContainer={alignment === 'Full Width'}
        className={alignmentType(alignment)}
        containerClassName={styles.container}
        sliceName="LargeImage"
      >
        <a.div
          className={styles.imageContainer}
          style={{
            opacity: aMain.opacity,
            transform: !alignment === 'Full Width' && aMain.transform,
          }}
        >
          {hasImage && <Image image={image} className={styles.image} link={imageLink} />}
        </a.div>
        {hasCta && (
          <Link
            className={`button outline ${background === 'Dark' || alignment === 'Full Width' ? 'white' : ''}`}
            to={ctaLink.url}
            anchor={anchorId?.text}
          >
            {ctaText.text}
          </Link>
        )}
      </Section>
    </div>
  );
};

export default LargeImage;
