import React, { useEffect } from 'react';
import { PlayButton } from 'components';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox-pro';
import * as styles from './styles.module.scss';

const GalleryBox = ({ imagePlaceholder, lightBoxVideo, ratio, customSize }) => {
  const { openLightbox, closeLightbox } = useLightbox();

  const lightboxOptions = {
    settings: {
      autoplaySpeed: 3000,
      boxShadow: 'none',
      disableKeyboardControls: false,
      disablePanzoom: false,
      disableWheelControls: false,
      hideControlsAfter: 3000,
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: 'linear',
      overlayColor: 'rgba(9, 9, 9, 0.9)',
      slideAnimationType: 'fade',
      slideSpringValues: [300, 50],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: 'linear',
      usingPreact: false,
    },
    buttons: {
      backgroundColor: 'rgba(9, 9, 9, 0.9)',
      iconColor: '#8DA3CB',
      iconPadding: '10px',
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: true,
      size: '40px',
    },
    thumbnails: {
      showThumbnails: false,
      thumbnailsAlignment: 'center',
      thumbnailsContainerBackgroundColor: 'transparent',
      thumbnailsContainerPadding: '0',
      thumbnailsGap: '0 5px',
      thumbnailsIconColor: '#ffffff',
      thumbnailsOpacity: 0.4,
      thumbnailsPosition: 'bottom',
      thumbnailsSize: ['100px', '80px'],
    },
  };

  // ratio calcs
  const calcAspect = (num1, num2) => ((num2 / num1) * 100).toFixed(2);
  const natrualAspectRatio = calcAspect(customSize?.width, customSize?.height) || 56;
  const ratioClasses = {
    '16:9': 'sixteenNine',
    '21:9': 'twentyOneNine',
    Custom: 'custom',
  };

  // open
  const handleClick = () => {
    // add appropriate ratio class
    document.body.classList.add(`aspect-${ratioClasses[ratio]}`);
    document.body.classList.add('use-ratio');
    // if dynamic, update global sass vraiable to correct padding
    if (ratio === 'Custom') {
      const root = document.querySelector(':root');
      root.style.setProperty('--dynamicPadding', `${natrualAspectRatio}%`);
    }
    openLightbox(0);
  };

  // close
  const callbacks = {
    onLightboxClosed: () => {
      // remove last-opened ratio class
      document.body.classList.remove(...document.body.classList);
      const root = document.querySelector(':root');
      // reset global padding variable
      root.style.setProperty('--dynamicPadding', 0);
    },
  };

  return (
    <div className={`${styles.lightboxVideo}`} onClick={handleClick} role="button" tabIndex={0}>
      <div className={styles.playButtonContainer}>
        <PlayButton className={styles.playButton} />
      </div>
      <SRLWrapper options={lightboxOptions} callbacks={callbacks}>
        <div className="imageContainerLightBox">
          <div>
            <a
              srl_video_loop="true"
              srl_video_scale="80"
              href={lightBoxVideo}
              srl_video_width={customSize?.width || 'auto'}
              srl_video_height={customSize?.height || 'auto'}
            >
              <img src={imagePlaceholder?.url} alt="Video Thumbnail" />
            </a>
          </div>
        </div>
      </SRLWrapper>
    </div>
  );
};

export default GalleryBox;
