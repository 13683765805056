// extracted by mini-css-extract-plugin
export var fullWidth = "styles-module--fullWidth--1f6D4";
export var container = "styles-module--container--3nZav";
export var image = "styles-module--image--1t8U1";
export var containerLeft = "styles-module--containerLeft--Lckeq";
export var containerRight = "styles-module--containerRight--3jW-c";
export var whiteBg = "styles-module--whiteBg--3auQo";
export var grid = "styles-module--grid--2JViU";
export var content = "styles-module--content--2Vd43";
export var line = "styles-module--line--2vtZ8";
export var offWhiteBg = "styles-module--offWhiteBg--3hc2I";
export var beigeBg = "styles-module--beigeBg--3Ai5I";
export var lightBg = "styles-module--lightBg--1x9fx";
export var text = "styles-module--text--2q0io";
export var darkBg = "styles-module--darkBg--1x-zN";