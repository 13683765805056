import React from 'react';
import { Section, Link } from 'components';
import * as styles from './styles.module.scss';

const Wysiwyg = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    content,
    title,
    background = 'White',
    cta_link: ctaLink,
    cta_text: ctaText,
    cta_anchor_id: anchorId,
    alignment,
  } = primary;

  // content check
  const contentHtml = content?.html;
  const titleHtml = title?.html;
  const ctaUrl = ctaLink?.url;
  const ctaButtonText = ctaText?.text;
  const hasCta = (ctaUrl || anchorId?.text) && ctaButtonText;

  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  const alignments = {
    Left: '', // default
    Center: styles.centerAligned,
    Right: styles.rightAligned,
  };

  const classNames = `${themes[background]} ${alignments[alignment]}`;

  return (
    <Section className={classNames} containerClassName={styles.container} sliceName="WYSIWYG">
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {contentHtml && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: contentHtml,
          }}
        />
      )}
      {hasCta && (
        <div className={styles.ctaRow}>
          <Link
            className={`button outline ${background === 'Dark' ? 'white' : ''}`}
            to={ctaUrl}
            anchor={anchorId?.text}
          >
            {ctaButtonText}
          </Link>
        </div>
      )}
    </Section>
  );
};

export default Wysiwyg;
