import React from 'react';
import * as styles from './styles.module.scss';

const AnchorId = (props) => {
  const { data } = props;
  const { primary } = data;
  const { id } = primary;

  if (!id?.text) return null;

  return <div id={id?.text} />;
};

export default AnchorId;
