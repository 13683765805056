import React from 'react';
import { Footer, Header, SEO, NoJs, WmAscii } from 'components';
import 'typeface-lato';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, customSEO, seo } = props;

  const uid = children?.[0]?.props?.uid;

  return (
    <>
      <NoJs />
      <WmAscii />
      <Header uid={uid} />
      <main>
        {!customSEO && <SEO {...seo} />}
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
