import React, { useState, useEffect } from 'react';
import { Section, Image, Link, Carousel } from 'components';
import { useInView } from 'react-intersection-observer';

import { a, config, useSpring } from 'react-spring';
import * as styles from './styles.module.scss';
import Download from '../../images/icons/download.svg';

import DownloadLight from '../../images/icons/download-light.svg';

const TwoColsFeatureRoomImage = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemIndex, setItemIndex] = useState(null);
  const { data } = props;
  const { primary, items } = data;
  const { cta_text: ctaText, title, image_alignment: imageAlignment, background } = primary;

  const itemsSorted = {};

  items.forEach((item) => {
    const titleText = item.title.text.trim();
    itemsSorted[titleText] = itemsSorted[titleText] || [];
    itemsSorted[titleText].push(item);
  });

  const finalArray = Object.keys(itemsSorted).map((key) => [String(key), itemsSorted[key]]);
  const firstImage = finalArray[0]?.[1]?.[0];

  const titleHtml = title?.html;
  const hasCta = ctaText?.text;
  const isLeftImage = imageAlignment === 'Left';

  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  // ANIMATIONS
  const [ref, inView] = useInView({
    threshold: 0,
    delay: 0,
    triggerOnce: true,
  });

  const [fadeIn, set] = useSpring(() => ({ opacity: 0 }));
  const [lineWidth, setLineWidth] = useSpring(() => ({ width: 0 }));

  const aDrapeLeft = useSpring({
    config: { ...config.molasses },
    delay: 200,
    transform: inView ? 'translateX(0px) scale(1)' : 'translateX(-200px) scale(0.8)',
  });

  const aMain = useSpring({
    config: { ...config.molasses },
    delay: 300,
    opacity: inView ? 1 : 0,
  });

  useEffect(() => {
    set({ opacity: 1, from: { opacity: 0 }, config: { ...config.molasses } });
    setLineWidth({ width: 50, from: { width: 0 }, config: { mass: 1, tension: 150, friction: 14 } });
  }, [set, selectedImage]);
  // ---------
  const onClick = (categoryItem, index) => {
    setSelectedImage(categoryItem);
    setItemIndex(index);
  };

  const download = (itemUrl) => {
    fetch(itemUrl, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${selectedImage ? selectedImage?.localFile?.name : firstImage?.localFile?.name}.${
              selectedImage ? selectedImage?.localFile?.extension : firstImage?.localFile?.extension
            }`
          );
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const Items = ({ categoryItems, index }) => (
    <>
      {categoryItems.map((categoryItem, categoryItemIndex) => {
        const { option, image } = categoryItem;
        const optionText = option?.text;

        const indexString = `${index}-${categoryItemIndex}`;

        const selectedItem = itemIndex === indexString;
        const firstLine = categoryItemIndex === 0 && index === 0 && !selectedImage;

        const categoryItemTitle = categoryItem?.title?.text;

        return (
          <div className={categoryItemTitle ? styles.list : styles.listNoTitle} key={`${categoryItemIndex + 1}`}>
            <a
              onClick={() => onClick(categoryItem, indexString)}
              role="button"
              tabIndex={0}
              className={styles.switcherButton}
            >
              {optionText && (
                <p className={selectedItem || firstLine ? styles.optionTextActive : styles.optionText}>{optionText}</p>
              )}
            </a>
            {(selectedItem || firstLine) && (
              <a.div className={isLeftImage ? styles.lineRight : styles.lineLeft} style={lineWidth} />
            )}
          </div>
        );
      })}
    </>
  );

  return (
    <div className={themes[background]}>
      <Section containerClassName={styles.section} sliceName="TwoColListImage">
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        <a.div className={isLeftImage ? styles.wrapper : styles.wrapperImageRight} style={{ opacity: aMain?.opacity }}>
          {firstImage && (
            <a.div className={styles.imageWrapper} style={fadeIn} ref={ref}>
              <Image
                image={selectedImage?.image || firstImage?.image}
                link={selectedImage?.image_link || firstImage?.image_link}
                className={styles.image}
                imgStyle={{
                  objectFit: 'contain',
                }}
              />
            </a.div>
          )}

          <div className={styles.listWrapper}>
            {finalArray.map((item, index) => {
              const categoryTitle = item[0];
              const categoryItems = item[1];

              return (
                <a.div
                  className={styles.listItems}
                  style={{ transform: aDrapeLeft?.transform, opacity: aMain?.opacity }}
                  key={`${index + 2}`}
                >
                  <span>
                    <h2 className={styles.categoryTitle}>{categoryTitle}</h2>
                  </span>
                  <Items categoryItems={categoryItems} index={index} />
                </a.div>
              );
            })}
          </div>
        </a.div>
        <div className={isLeftImage ? styles.bottomRowRight : styles.bottomRowLeft}>
          {hasCta && (
            <>
              <img
                src={background && background === 'Dark' ? DownloadLight : Download}
                className={styles.download}
                alt="Download"
              />
              <a
                download
                role="button"
                tabIndex={0}
                className={styles.downloadCta}
                onClick={() => download(selectedImage?.url || firstImage?.url)}
              >
                {ctaText.text}
              </a>
            </>
          )}
        </div>
      </Section>
    </div>
  );
};

export default TwoColsFeatureRoomImage;
