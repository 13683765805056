import React from 'react';
import { Section } from 'components';
import { a, config, useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import * as styles from './styles.module.scss';

const Quote = (props) => {
  const { data } = props;
  const { primary } = data;
  const { job, name, quote, background, drape } = primary;

  const jobHtml = job?.html;
  const nameHtml = name?.html;
  const quoteHtml = quote?.html;

  const [ref, inView] = useInView({
    threshold: 0,
    delay: 200,
    triggerOnce: true,
  });

  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  const drapeBg = {
    Left: styles.leftDrapeBg,
    Right: styles.rightDrapeBg,
  };

  // ANIMATIONS
  const aMain = useSpring({
    config: { ...config.molasses },
    delay: 200,
    opacity: inView ? 1 : 0,
  });

  const aDrapeLeft = useSpring({
    config: { ...config.molasses },
    delay: 700,
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateX(0px) scale(1)' : 'translateX(-200px) scale(0.8)',
  });

  const aDrapeRight = useSpring({
    config: { ...config.molasses },
    delay: 700,
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateX(0px) rotate(180deg) scale(1)' : 'translateX(200px) rotate(180deg) scale(0.8)',
  });

  return (
    <div ref={ref} className={`${styles.container} ${themes[background]}`}>
      <a.div style={{ opacity: aMain.opacity }}>
        <a.div
          className={drapeBg[drape]}
          style={{
            transform: drape === 'Right' ? aDrapeRight.transform : aDrapeLeft.transform,
            opacity: aDrapeLeft.opacity,
          }}
          alt="Drape"
        />
        <Section containerClassName={styles.container} sliceName="Quote">
          {quoteHtml && <div dangerouslySetInnerHTML={{ __html: quoteHtml }} className={styles.quote} />}
          <div className={styles.wrapper}>
            <div className={styles.divider}>
              <div />
              <div />
            </div>
            {nameHtml && <div dangerouslySetInnerHTML={{ __html: nameHtml }} className={styles.name} />}
            {jobHtml && <div dangerouslySetInnerHTML={{ __html: jobHtml }} className={styles.job} />}
          </div>
        </Section>
      </a.div>
    </div>
  );
};

export default Quote;
