import React, { useState } from 'react';
import { Link, Image, Logo } from 'components';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { use100vh } from 'react-div-100vh';
import { a, config, useSpring, useTransition, useTrail } from 'react-spring';
import * as styles from './styles.module.scss';
import { ReactComponent as Cross } from '../../images/icons/cross.svg';
import { ReactComponent as Facebook } from '../../images/icons/facebook.svg';
import { ReactComponent as Instagram } from '../../images/icons/instagram.svg';
import { ReactComponent as LinkedIn } from '../../images/icons/linkedin.svg';

const Navigation = (props) => {
  const { classNamePrefix = 'main', links, menuToggle, setMenuToggle, uid } = props;
  const className = classNamePrefix ? `${classNamePrefix}-navigation` : 'navigation';

  const navQuery = graphql`
    query {
      ...NavLinks
    }
  `;

  const {
    prismicSettings: { data: navLinks },
  } = useStaticQuery(navQuery);

  const { navigation_links: navItems } = navLinks;

  // 100VH HEIGHT FOR MOBILE BROWSER CONSIDERATION
  const height = use100vh();

  // LINKS

  const socialLinks = [
    { to: 'https://www.facebook.com/serainorthfremantle/', text: 'Facebook', className: `${styles.socialItem}` },
    { to: '/', text: 'LinkedIn', className: `${styles.socialItem}` },
    { to: 'https://www.instagram.com/serai.northfremantle/', text: 'Instagram', className: `${styles.socialItem}` },
  ];

  const subLinks = [
    { to: '/privacy-policy', text: 'Privacy Policy', className: `${styles.subItem}` },
    { to: '/terms-of-use', text: 'Terms of Use', className: `${styles.subItem}` },
  ];

  // ANIMATIONS

  const aMain = useSpring({
    config: { mass: 1, tension: menuToggle ? 280 : 120, friction: menuToggle ? 120 : 20 },
    delay: (key) => (key === 'opacity' ? 120 : key === 'display' && !menuToggle ? 1500 : 0),
    opacity: menuToggle ? 1 : 0,
    display: menuToggle ? 'block' : 'none',
  });

  const aBg = useSpring({
    config: { ...config.molasses },
    delay: menuToggle ? 700 : 0,
    opacity: menuToggle ? 1 : 0,
    transform: menuToggle ? 'scale(1)' : 'scale(1.5)',
    reverse: !menuToggle,
  });

  const aLogo = useSpring({
    config: { ...config.molasses },
    delay: menuToggle ? 600 : 0,
    opacity: menuToggle ? 1 : 0,
    transform: menuToggle
      ? 'translateY(0px) translateX(calc(-50% - 10px))'
      : 'translateY(-100px) translateX(calc(-50% - 10px))',
    reverse: !menuToggle,
  });

  const aMainLinksTrail = useTrail(navItems.length, {
    config: { mass: 5, tension: 2000, friction: 250 },
    delay: menuToggle ? 300 : 0,
    from: { opacity: 0, y: 50 },
    to: {
      opacity: menuToggle ? 1 : 0,
      y: menuToggle ? 0 : 50,
    },
  });

  const aSocialLinks = useSpring({
    config: { ...config.molasses },
    delay: menuToggle ? 700 : 0,
    opacity: menuToggle ? 1 : 0,
    transform: menuToggle
      ? 'translateY(calc(-50%)) translateX(calc(-250%)'
      : 'translateY(calc(-50%)) translateX(calc(-275%)',
    reverse: !menuToggle,
  });

  const aSubNavLinks = useSpring({
    config: { ...config.molasses },
    delay: menuToggle ? 800 : 0,
    opacity: menuToggle ? 1 : 0,
    transform: menuToggle
      ? 'translateY(0px) translateX(calc(-150% - 10px))'
      : 'translateY(50px) translateX(calc(-150% - 10px))',
    reverse: !menuToggle,
  });

  return (
    <a.nav
      className={`${className} ${styles.container}`}
      style={{ height, opacity: aMain.opacity, display: aMain.display }}
    >
      <a.div className={styles.logo} style={{ opacity: aLogo.opacity, transform: aLogo.transform }}>
        <Logo light />
      </a.div>

      <a.div style={{ opacity: aBg.opacity, transform: aBg.transform }}>
        <StaticImage src="../../images/drapes/drape-nav.png" className={styles.drape} alt="Drape" />
      </a.div>

      <Cross className={styles.cross} onClick={() => setMenuToggle(false)} />

      <div className={styles.navContainer}>
        {aMainLinksTrail.map(({ y, opacity }, index) => {
          const isActive = navItems[index]?.navigation_link?.slug === uid;

          return (
            <div className={styles.navWrapper}>
              <a.div
                key={`${index + 2}`}
                className="trails-text"
                style={{
                  opacity,
                  transform: y.interpolate((y) => `translate3d(0,${y}px,0)`),
                }}
              >
                <Link
                  key={`${index + 1}`}
                  className={isActive ? styles.active || '' : `${styles.navItem}-link ${styles.navItem || ''}`}
                  to={`/${navItems[index]?.navigation_link?.slug}`}
                >
                  {navItems[index]?.navigation_link?.slug}
                </Link>
              </a.div>
            </div>
          );
        })}
      </div>

      <a.div
        className={styles.socialNavContainer}
        style={{ opacity: aSocialLinks.opacity, transform: aSocialLinks.transform }}
      >
        <a
          className={styles.socialItem}
          href="https://www.facebook.com/serainorthfremantle/"
          target="_blank"
          rel="noreferrer"
        >
          <Facebook fill="#FAFAFA" height={40} width={40} />
        </a>
        <a
          className={styles.socialItem}
          href="https://www.instagram.com/serai.northfremantle/"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram fill="#FAFAFA" height={40} width={40} />
        </a>
        <a
          className={styles.socialItem}
          href="https://www.linkedin.com/company/megara-property/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedIn fill="#FAFAFA" height={40} width={40} />
        </a>

        <div className={styles.contactContainer}>
          <p>Stephen Vitale</p>
          <a href="mailto:stephen@serai.com.au">stephen@serai.com.au</a>
          <a href="tel:0416095906">0416 095 906</a>
        </div>
      </a.div>

      {/* <a.div
        className={styles.subNavContainer}
        style={{ opacity: aSubNavLinks.opacity, transform: aSubNavLinks.transform }}
      >
        {subLinks &&
          subLinks.map((link) => (
            <Link key={link.text} className={`${className}-link ${link.className || ''}`} target="_blank" to={link.to}>
              {link.text}
            </Link>
          ))}
      </a.div> */}
    </a.nav>
  );
};

export default Navigation;
