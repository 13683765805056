import React, { useRef, useState, useEffect } from 'react';
import { Section, GalleryBox, Link } from 'components';
import SimpleReactLightbox from 'simple-react-lightbox-pro';

import * as styles from './styles.module.scss';

const LargeVideo = (props) => {
  const { data } = props;

  const {
    video_link: videoLink,
    placeholder,
    drapes,
    background,
    cta_link: ctaLink,
    cta_text: ctaText,
    cta_anchor_id: anchorId,
    aspect_ratio: aspectRatio,
    embed_code: embedCode,
  } = data?.primary;

  // THEME SETUP
  const themes = {
    White: styles.whiteBg,
    'Off-White': styles.offWhiteBg,
    Beige: styles.beigeBg,
    Light: styles.lightBg,
    Dark: styles.darkBg,
  };

  const drapeBg = {
    Small: styles.smallDrapeBg,
    Large: styles.largeDrapeBg,
  };

  // embed option stuff
  const [embedParams, setEmbedParams] = useState(null);
  const embedRef = useRef(null);
  const embedHtml = embedCode?.text;

  useEffect(() => {
    if (embedHtml) {
      const iframeEl = embedRef?.current ? embedRef?.current.querySelector('iframe') : {};
      const { width, height, src } = iframeEl;
      setEmbedParams({ width, height, src });
    }
  }, []);

  return (
    <Section sliceName="LargeVideo" className={`${themes[background]} ${drapeBg[drapes]}`}>
      {/* INSERT EMBED INTO DOM TO GET REF VALUES TO PASS TO lightBoxVideo */}
      {embedHtml && (
        <div className={styles.hiddenEmbed} ref={embedRef} dangerouslySetInnerHTML={{ __html: embedHtml }} />
      )}
      {/* LIGHTBOX accepts video link directly or src from embed */}
      <div className={styles.youtubeVideo}>
        <div className={styles.containerGalleryBox}>
          <SimpleReactLightbox>
            <GalleryBox
              imagePlaceholder={placeholder}
              lightBoxVideo={embedParams?.src || videoLink?.url}
              ratio={aspectRatio}
              customSize={embedParams}
            />
          </SimpleReactLightbox>
        </div>
      </div>
      {(ctaLink?.url || anchorId?.text) && ctaText?.text && (
        <Link
          to={ctaLink?.url}
          className={`button outline ${background === 'Dark' ? 'secondary' : ''} ${styles.cta}`}
          anchor={anchorId?.text}
        >
          {ctaText?.text}
        </Link>
      )}
    </Section>
  );
};

export default LargeVideo;
